import {
  GET_CHAPTERLISTING,
  GET_CHAPTERLISTING_SUCCESS,
  GET_CHAPTERLISTING_FAILED,
  ADD_CHAPTERLISTING_SUCCESS,
  ADD_CHAPTER_MODAL,
  ADD_CHAPTERLISTING,
  DELETE_CHAPTERLISTING,
  DELETE_CHAPTERLISTING_SUCCESS,
  UPDATE_CHAPTERLISTING,
  UPDATE_CHAPTERLISTING_SUCCESS,
  UPDATE_CHAPTERLISTING_FAILED,
  GET_CHAPTERLISTING_SEQUENCE,
  GET_CHAPTERLISTING_SEQUENCE_SUCCESS,
  GET_CHAPTERLISTING_SEQUENCE_FAILED,
  ADD_CHAPTERLISTING_FAILED,
} from './actionType';

const INIT_STATE = {
  chapterListing: null,
  chapterSequenceNo: 0,
  openModal: false,
  isProcessed: true,
  loading: false,
  processLoading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHAPTERLISTING:
      return { ...state, loading: true, error: '' };
    case GET_CHAPTERLISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        chapterListing: action.payload,
        error: '',
      };
    case GET_CHAPTERLISTING_FAILED:
      return {
        ...state,
        loading: false,
        chapterListing: null,
        error: action.payload.message,
      };
    case GET_CHAPTERLISTING_SEQUENCE:
      return { ...state, error: '' };
    case GET_CHAPTERLISTING_SEQUENCE_SUCCESS:
      return {
        ...state,
        chapterSequenceNo: action.payload,
        error: '',
      };
    case GET_CHAPTERLISTING_SEQUENCE_FAILED:
      return {
        ...state,
        chapterSequenceNo: 0,
        error: action.payload.message,
      };
    case ADD_CHAPTER_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ADD_CHAPTERLISTING:
      return {
        ...state,
        isProcessed: false,
        processLoading: true,
      };
    case ADD_CHAPTERLISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
        processLoading: false,
      };
    case ADD_CHAPTERLISTING_FAILED:
      return {
        ...state,
        processLoading: false,
      };
    case DELETE_CHAPTERLISTING:
      return {
        ...state,
        isProcessed: false,
      };
    case DELETE_CHAPTERLISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
      };
    case UPDATE_CHAPTERLISTING:
      return {
        ...state,
        isProcessed: false,
        processLoading: true,
      };
    case UPDATE_CHAPTERLISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
        processLoading: false,
      };
    case UPDATE_CHAPTERLISTING_FAILED:
      return {
        ...state,
        processLoading: false,
      };
    default:
      return { ...state };
  }
};
