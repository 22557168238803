import React from 'react';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';
import CryptoJS, { AES } from 'crypto-js';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};
export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};
export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};
export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};
export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};
export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};
export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('vlsUser') != null
        ? JSON.parse(localStorage.getItem('vlsUser'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user, token) => {
  try {
    if (user) {
      localStorage.setItem('vlsUser', JSON.stringify(user));
      localStorage.setItem('vlsAuthToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('vlsUser');
      localStorage.removeItem('vlsAuthToken');
      localStorage.removeItem('ExternalLogin');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const setCurrentUserMenu = (menu) => {
  try {
    if (menu) {
      localStorage.setItem('vlsUserMenu', JSON.stringify(menu));
    } else {
      localStorage.removeItem('vlsUserMenu');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentUserMenu -> error',
      error
    );
  }
};

export const getCurrentUserMenu = () => {
  let menu = null;
  try {
    menu =
      localStorage.getItem('vlsUserMenu') != null
        ? JSON.parse(localStorage.getItem('vlsUserMenu'))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getCurrentUserMenu -> error',
      error
    );
    menu = null;
  }
  return menu;
};

export const getAuthToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem('vlsAuthToken') != null
        ? JSON.parse(localStorage.getItem('vlsAuthToken'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getAuthToken -> error', error);
    token = null;
  }
  return token;
};

export const setAuthToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('vlsAuthToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('vlsAuthToken');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setAuthToken -> error', error);
  }
};

export const getUserType = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('vlsUser') != null
        ? JSON.parse(localStorage.getItem('vlsUser'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user.user.user_type;
};

export const getBrandInfo = () => {
  let brandInfo = null;
  try {
    brandInfo =
      localStorage.getItem('vlsUser') != null
        ? JSON.parse(localStorage.getItem('vlsUser'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    brandInfo = null;
  }

  brandInfo = {
    brand_id: brandInfo.user.brand_id,
    brand_name: brandInfo.user.brand_name,
    user_id: brandInfo.user.id,
    user_name: brandInfo.user.name,
  };
  return brandInfo;
};

export const ExternalLogin = (props) => {
  const { extraProp } = props;
  const currentUser = getCurrentUser();

  const { redirectKey, user } = currentUser;

  const decryptBytes = AES.decrypt(redirectKey, 'g0D@arkk.11');
  const decryptedData = JSON.parse(decryptBytes.toString(CryptoJS.enc.Utf8));

  const formRef = React.useRef(null);

  React.useEffect(() => {
    async function fetchMyAPI() {
      const externalLogin = await localStorage.getItem('ExternalLogin');
      console.log('External Login', externalLogin);
      if (externalLogin) {
        console.log(`Redirect - http://admin.sadaynaal.com/${extraProp}`);
        window.location.href = `http://admin.sadaynaal.com/${extraProp}`;
      } else if (formRef.current != null) {
        localStorage.setItem('ExternalLogin', true);
        formRef.current.submit();
      }
    }

    fetchMyAPI();
  });

  return (
    <form
      ref={formRef}
      action="http://admin.sadaynaal.com/login"
      method="post"
      className="hideForm"
    >
      <input type="email" name="email" value={user.email} />
      <input type="password" name="password" value={decryptedData} />
      {extraProp !== '' && <input type="text" name="route" value={extraProp} />}
    </form>
  );
};
