/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi, showToastMessage } from '../util';
import {
  ADD_CHAPTERLISTING,
  DELETE_CHAPTERLISTING,
  GET_CHAPTERLISTING,
  GET_CHAPTERLISTING_SEQUENCE,
  UPDATE_CHAPTERLISTING,
  // UPDATE_CHAPTER_SORTING,
} from './actionType';
import {
  getChapterListingSuccess,
  getChapterListingFailed,
  addChapterListingSuccess,
  addChapterListingFailed,
  deleteChapterListingSuccess,
  deleteChapterListingFailed,
  // updateChapterSortingSuccess,
  // updateChapterSortingFailed,
  getChapterListingSequenceSuccess,
  getChapterListingSequenceFailed,
} from './actions';

function* fetchChapterListing({ payload }) {
  const { CourseId, isDropDownListing } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/chapter/getChapters?courseId=${CourseId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    if (isDropDownListing) {
      const dataList = data.map((item) => ({
        label: item.Name,
        value: item.Name,
        key: item.Id,
        other: item,
      }));
      yield put(getChapterListingSuccess(dataList));
    } else {
      yield put(getChapterListingSuccess(data));
    }
  } else {
    const { message } = response.data;
    yield put(getChapterListingFailed(message));
  }
}

function* fetchChapterListingSequence({ payload }) {
  const { CourseId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/chapter/getChapters?courseId=${CourseId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    const tempdata = []
      .concat(data)
      .sort((a, b) => (a?.SortId < b.SortId ? 1 : -1));

    let lastSequenceNo = 0;

    if (tempdata.length) {
      lastSequenceNo = tempdata[0]?.SortId != null ? tempdata[0]?.SortId : 0;
    }

    yield put(getChapterListingSequenceSuccess(lastSequenceNo));
  } else {
    const { message } = response.data;
    yield put(getChapterListingSequenceFailed(message));
  }
}

function* addChapterListing({ payload }) {
  const { Chapters } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/chapter/addChapter`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
    data: Chapters,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(addChapterListingSuccess());
    showToastMessage(status, 'Add Chapter Successfully');
  } else {
    const { message } = response.data;
    yield put(addChapterListingFailed(message));
    showToastMessage(status, message);
  }
}

function* updateChapterListing({ payload }) {
  const { Chapter } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/chapter/updateChapter`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: Chapter,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(addChapterListingSuccess());
    showToastMessage(status, 'Update topic successfully');
  } else {
    const { message } = response.data;
    yield put(addChapterListingFailed(message));
    showToastMessage(status, message);
  }
}

function* deleteChapterListing({ payload }) {
  const { Chapter } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/chapter/deleteChapter`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'DELETE',
    data: Chapter,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(deleteChapterListingSuccess());
    showToastMessage(status, 'Delete topic successfully');
  } else {
    const { message } = response.data;
    yield put(deleteChapterListingFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

/* function* updateChapterSorting({ payload }) {
  const { Listing } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/chapter/updateChapterWithSorting`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: Listing,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(updateChapterSortingSuccess());
    showToastMessage(status, 'Update Listing Successfully');
  } else {
    const { message } = response.data;
    yield put(updateChapterSortingFailed(message));
    showToastMessage(status, 'Update Listing Failed');
  }
}
 */

export function* watchChapters() {
  yield takeEvery(GET_CHAPTERLISTING, fetchChapterListing);
  yield takeEvery(ADD_CHAPTERLISTING, addChapterListing);
  yield takeEvery(DELETE_CHAPTERLISTING, deleteChapterListing);
  yield takeEvery(UPDATE_CHAPTERLISTING, updateChapterListing);
  yield takeEvery(GET_CHAPTERLISTING_SEQUENCE, fetchChapterListingSequence);
}

export default function* rootSaga() {
  yield all([fork(watchChapters)]);
}
