export const GET_ENROLLMENT_LISTING = 'GET_ENROLLMENT_LISTING';
export const GET_ENROLLMENT_LISTING_SUCCESS = 'GET_ENROLLMENT_LISTING_SUCCESS';
export const GET_ENROLLMENT_LISTING_FAILED = 'GET_ENROLLMENT_LISTING_FAILED';

export const DELETE_ENROLLMENT = 'DELETE_ENROLLMENT';
export const DELETE_ENROLLMENT_SUCCESS = 'DELETE_ENROLLMENT_SUCCESS';
export const DELETE_ENROLLMENT_FAILED = 'DELETE_ENROLLMENT_FAILED';

export const ADD_ENROLLMENT = 'ADD_ENROLLMENT';
export const ADD_ENROLLMENT_SUCCESS = 'ADD_ENROLLMENT_SUCCESS';
export const ADD_ENROLLMENT_FAILED = 'ADD_ENROLLMENT_FAILED';

export const SHOW_ENROLLMENT_MODAL = 'SHOW_ENROLLMENT_MODAL';
export const HIDE_ENROLLMENT_MODAL = 'HIDE_ENROLLMENT_MODAL';
