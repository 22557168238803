export const GET_TOPICLISTING = 'GET_TOPICLISTING';
export const GET_TOPICLISTING_SUCCESS = 'GET_TOPICLISTING_SUCCESS';
export const GET_TOPICLISTING_FAILED = 'GET_TOPICLISTING_FAILED';

export const GET_TOPICLISTING_SEQUENCE = 'GET_TOPICLISTING_SEQUENCE';
export const GET_TOPICLISTING_SEQUENCE_SUCCESS =
  'GET_TOPICLISTING_SEQUENCE_SUCCESS';
export const GET_TOPICLISTING_SEQUENCE_FAILED =
  'GET_TOPICLISTING_SEQUENCE_FAILED';

export const ADD_TOPICLISTING = 'ADD_TOPICLISTING';
export const ADD_TOPICLISTING_SUCCESS = 'ADD_TOPICLISTING_SUCCESS';
export const ADD_TOPICLISTING_FAILED = 'ADD_TOPICLISTING_FAILED';

export const UPDATE_TOPICLISTING = 'UPDATE_TOPICLISTING';
export const UPDATE_TOPICLISTING_SUCCESS = 'UPDATE_TOPICLISTING_SUCCESS';
export const UPDATE_TOPICLISTING_FAILED = 'UPDATE_TOPICLISTING_FAILED';

export const DELETE_TOPICLISTING = 'DELETE_TOPICLISTING';
export const DELETE_TOPICLISTING_SUCCESS = 'DELETE_TOPICLISTING_SUCCESS';
export const DELETE_TOPICLISTING_FAILED = 'DELETE_TOPICLISTING_FAILED';

export const GET_TOPIC_TYPELISTING = 'GET_TOPIC_TYPELISTING';
export const GET_TOPIC_TYPELISTING_SUCCESS = 'GET_TOPIC_TYPELISTING_SUCCESS';
export const GET_TOPIC_TYPELISTING_FAILED = 'GET_TOPIC_TYPELISTING_FAILED';

export const ADD_TOPIC_TYPELISTING = 'ADD_TOPIC_TYPELISTING';
export const ADD_TOPIC_TYPELISTING_SUCCESS = 'ADD_TOPIC_TYPELISTING_SUCCESS';
export const ADD_TOPIC_TYPELISTING_FAILED = 'ADD_TOPIC_TYPELISTING_FAILED';

export const UPDATE_TOPIC_TYPELISTING = 'UPDATE_TOPIC_TYPELISTING';
export const UPDATE_TOPIC_TYPELISTING_SUCCESS =
  'UPDATE_TOPIC_TYPELISTING_SUCCESS';
export const UPDATE_TOPIC_TYPELISTING_FAILED =
  'UPDATE_TOPIC_TYPELISTING_FAILED';

export const DELETE_TOPIC_TYPELISTING = 'DELETE_TOPIC_TYPELISTING';
export const DELETE_TOPIC_TYPELISTING_SUCCESS =
  'DELETE_TOPIC_TYPELISTING_SUCCESS';
export const DELETE_TOPIC_TYPELISTING_FAILED =
  'DELETE_TOPIC_TYPELISTING_FAILED';

export const ADD_TOPIC_MODAL = 'ADD_TOPIC_MODAL';

export const UPDATE_TOPIC_SORTING = 'UPDATE_TOPIC_SORTING';
export const UPDATE_TOPIC_SORTING_SUCCESS = 'UPDATE_TOPIC_SORTING_SUCCESS';
export const UPDATE_TOPIC_SORTING_FAILED = 'UPDATE_TOPIC_SORTING_FAILED';
