import {
  GET_TOPICLISTING,
  GET_TOPICLISTING_SUCCESS,
  GET_TOPICLISTING_FAILED,
  GET_TOPIC_TYPELISTING,
  GET_TOPIC_TYPELISTING_SUCCESS,
  GET_TOPIC_TYPELISTING_FAILED,
  ADD_TOPICLISTING_SUCCESS,
  ADD_TOPIC_MODAL,
  ADD_TOPICLISTING,
  DELETE_TOPICLISTING,
  DELETE_TOPICLISTING_SUCCESS,
  ADD_TOPIC_TYPELISTING,
  ADD_TOPIC_TYPELISTING_SUCCESS,
  DELETE_TOPIC_TYPELISTING_SUCCESS,
  DELETE_TOPIC_TYPELISTING,
  UPDATE_TOPIC_TYPELISTING,
  UPDATE_TOPIC_TYPELISTING_SUCCESS,
  UPDATE_TOPICLISTING,
  UPDATE_TOPICLISTING_SUCCESS,
  UPDATE_TOPIC_SORTING,
  UPDATE_TOPIC_SORTING_SUCCESS,
  GET_TOPICLISTING_SEQUENCE,
  GET_TOPICLISTING_SEQUENCE_SUCCESS,
  GET_TOPICLISTING_SEQUENCE_FAILED,
  ADD_TOPICLISTING_FAILED,
  UPDATE_TOPICLISTING_FAILED,
} from './actionType';

const INIT_STATE = {
  topicListing: null,
  topicTypeListing: null,
  topicSequenceNo: 0,
  openModal: false,
  isProcessed: true,
  loading: false,
  processLoading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOPICLISTING:
      return { ...state, loading: true, error: '' };
    case GET_TOPICLISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        topicListing: action.payload,
        error: '',
      };
    case GET_TOPICLISTING_FAILED:
      return {
        ...state,
        loading: false,
        topicListing: null,
        error: action.payload.message,
      };
    case GET_TOPICLISTING_SEQUENCE:
      return { ...state, error: '' };
    case GET_TOPICLISTING_SEQUENCE_SUCCESS:
      return {
        ...state,
        topicSequenceNo: action.payload,
        error: '',
      };
    case GET_TOPICLISTING_SEQUENCE_FAILED:
      return {
        ...state,
        topicSequenceNo: 0,
        error: action.payload.message,
      };
    case GET_TOPIC_TYPELISTING:
      return { ...state, topicTypeListing: null, loading: true, error: '' };
    case GET_TOPIC_TYPELISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        topicTypeListing: action.payload,
        error: '',
      };
    case GET_TOPIC_TYPELISTING_FAILED:
      return {
        ...state,
        loading: false,
        topicTypeListing: null,
        error: action.payload.message,
      };
    case ADD_TOPIC_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case ADD_TOPICLISTING:
      return {
        ...state,
        isProcessed: false,
        processLoading: true,
      };
    case ADD_TOPICLISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
        processLoading: false,
      };
    case ADD_TOPICLISTING_FAILED:
      return {
        ...state,
        processLoading: false,
      };
    case DELETE_TOPICLISTING:
      return {
        ...state,
        isProcessed: false,
      };
    case DELETE_TOPICLISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
      };
    case ADD_TOPIC_TYPELISTING:
      return {
        ...state,
        isProcessed: false,
      };
    case DELETE_TOPIC_TYPELISTING:
      return {
        ...state,
        isProcessed: false,
      };
    case UPDATE_TOPIC_TYPELISTING:
      return {
        ...state,
        isProcessed: false,
      };
    case ADD_TOPIC_TYPELISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
      };
    case DELETE_TOPIC_TYPELISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
      };
    case UPDATE_TOPIC_TYPELISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
      };
    case UPDATE_TOPICLISTING:
      return {
        ...state,
        isProcessed: false,
        processLoading: true,
      };
    case UPDATE_TOPICLISTING_SUCCESS:
      return {
        ...state,
        openModal: false,
        isProcessed: true,
        processLoading: false,
      };
    case UPDATE_TOPICLISTING_FAILED:
      return {
        ...state,
        processLoading: false,
      };
    case UPDATE_TOPIC_SORTING:
      return {
        ...state,
        isProcessed: false,
      };
    case UPDATE_TOPIC_SORTING_SUCCESS:
      return {
        ...state,
        isProcessed: true,
      };
    default:
      return { ...state };
  }
};
