import {
  GET_USERLISTING,
  GET_USERLISTING_SUCCESS,
  GET_USERLISTING_FAILED,
} from './actionType';

const INIT_STATE = {
  userListing: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERLISTING:
      return { ...state, loading: true, error: '' };
    case GET_USERLISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        userListing: action.payload,
        error: '',
      };
    case GET_USERLISTING_FAILED:
      return {
        ...state,
        loading: false,
        userListing: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
