/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi, showToastMessage } from '../util';
import {
  DELETE_SCHEDULE_TIMELINE,
  GET_SCHEDULE_COURSELISTING,
  GET_SCHEDULE_TIMELINE,
  REQUEST_SCHEDULE_TIMELINE,
  SAVE_SCHEDULE_TIMELINE,
  UPDATE_TASK_STATUS,
} from './actionType';
import {
  getScheduleCourseListingSuccess,
  getScheduleCourseListingFailed,
  getScheduleTimelineSuccess,
  getScheduleTimelineFailed,
  requestScheduleTimelineSuccess,
  requestScheduleTimelineFailed,
  saveScheduleTimelineSuccess,
  saveScheduleTimelineFailed,
  deleteScheduleTimelineSuccess,
  deleteScheduleTimelineFailed,
  updateTaskStatusSuccess,
  updateTaskStatusFailed,
} from './actions';

const viewScheduleRoute = '/app/vls/scheduler/viewSchedule';

function* fetchScheduleCourseListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/scheduler/getSchedulerListing`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getScheduleCourseListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getScheduleCourseListingFailed(message));
  }
}

function* fetchScheduleInfo({ payload }) {
  const { ScheduleId, isEdit } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/scheduler/getSchedulerInfo?id=${ScheduleId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getScheduleTimelineSuccess(data, isEdit));
  } else {
    const { message } = response.data;
    yield put(getScheduleTimelineFailed(message));
  }
}

function* requestScheduleInfo({ payload }) {
  const { params } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/courseScheduler/getCourseScheduler`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
    params: {
      ...params,
      daysArray: params.daysArray.toString(),
    },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(requestScheduleTimelineSuccess(data));
  } else {
    const { message } = response.data;
    yield put(requestScheduleTimelineFailed(message));
  }
}

function* saveScheduleInfo({ payload }) {
  const { roadMap, history, isEdit } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/scheduler/${
      !isEdit ? 'postScheduler' : 'editScheduler'
    }`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
    data: roadMap,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(saveScheduleTimelineSuccess());
    showToastMessage(status, 'Schedule saved successfully');
    history.push(viewScheduleRoute);
  } else {
    const { message } = response.data;
    yield put(saveScheduleTimelineFailed(message));
    showToastMessage(status, message);
  }
}

function* deleteSchedule({ payload }) {
  const { scheduleId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/scheduler/deleteScheduler`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: { schedulerId: scheduleId },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(deleteScheduleTimelineSuccess());
    showToastMessage(status, 'Schedule delete successfully');
  } else {
    const { message } = response.data;
    yield put(deleteScheduleTimelineFailed(message));
    showToastMessage(status, message);
  }
}

function* updateTaskStatus({ payload }) {
  const { taskId, statusId, dayIndex, topicIndex } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/scheduler/updateTopicStatus`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: [{ id: taskId, taskStatusId: statusId }],
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(updateTaskStatusSuccess(dayIndex, topicIndex, statusId));
    showToastMessage(status, 'Task status update successfully');
  } else {
    const { message } = response.data;
    yield put(updateTaskStatusFailed(message));
    showToastMessage(status, message);
  }
}

export function* watchScheuleCourses() {
  yield takeEvery(GET_SCHEDULE_COURSELISTING, fetchScheduleCourseListing);
  yield takeEvery(GET_SCHEDULE_TIMELINE, fetchScheduleInfo);
  yield takeEvery(REQUEST_SCHEDULE_TIMELINE, requestScheduleInfo);
  yield takeEvery(SAVE_SCHEDULE_TIMELINE, saveScheduleInfo);
  yield takeEvery(DELETE_SCHEDULE_TIMELINE, deleteSchedule);
  yield takeEvery(UPDATE_TASK_STATUS, updateTaskStatus);
}

export default function* rootSaga() {
  yield all([fork(watchScheuleCourses)]);
}
