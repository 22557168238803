import {
  GET_COURSELISTING,
  GET_COURSELISTING_SUCCESS,
  GET_COURSELISTING_FAILED,
  GET_COURSE_TIMELINE,
  GET_COURSE_TIMELINE_SUCCESS,
  GET_COURSE_TIMELINE_FAILED,
} from './actionType';

const INIT_STATE = {
  courseListing: null,
  timeline: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COURSELISTING:
      return { ...state, loading: true, error: '' };
    case GET_COURSELISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        courseListing: action.payload,
        error: '',
      };
    case GET_COURSELISTING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_COURSE_TIMELINE:
      return { ...state, loading: true, error: '' };
    case GET_COURSE_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeline: action.payload,
        error: '',
      };
    case GET_COURSE_TIMELINE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
