/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi } from '../util';
import { GET_COURSELISTING, GET_COURSE_TIMELINE } from './actionType';
import {
  getCourseListingSuccess,
  getCourseListingFailed,
  getCourseTimelineSuccess,
  getCourseTimelineFailed,
} from './actions';

function* fetchCourseListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/courseListing/getCourseListing`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getCourseListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getCourseListingFailed(message));
  }
}

function* fetchCourseTimeline({ payload }) {
  const { courseId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicListing/getTopicListing?courseId=${courseId}&isGroup=${false}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getCourseTimelineSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getCourseTimelineFailed(message));
  }
}

export function* watchCourses() {
  yield takeEvery(GET_COURSELISTING, fetchCourseListing);
  yield takeEvery(GET_COURSE_TIMELINE, fetchCourseTimeline);
}

export default function* rootSaga() {
  yield all([fork(watchCourses)]);
}
