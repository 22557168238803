import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken, setAuthToken } from 'helpers/Utils';

export async function CallApi(apiOptions) {
  let apiResponse = {};

  const config = {
    method: apiOptions.method,
    url: apiOptions.endpoint,
    headers: apiOptions.headers,
    data: apiOptions?.data,
    params: apiOptions.params,
  };

  await axios(config)
    .then((result) => {
      apiResponse = {
        response: result,
        status: true,
      };
    })
    .catch(async (error) => {
      const { status } = error.response;

      if (status === 401) {
        // eslint-disable-next-line no-use-before-define
        await refreshAuth();
      }
      apiResponse = {
        message: error.response,
        status: false,
      };
    });

  return apiResponse;
}

export const showToastMessage = (success, message) => {
  if (success) {
    NotificationManager.success(
      'Looks Great!',
      message,
      3000,
      null,
      null,
      'filled'
    );
  } else {
    NotificationManager.error(
      'Something went wrong!',
      message,
      3000,
      null,
      null,
      'filled'
    );
  }
};

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const refreshAuth = async () => {
  const apiOptions = {
    endpoint: `${baseURL}/api/auth/refreshToken`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data: { token: getAuthToken() },
  };
  const apiResponse = await CallApi(apiOptions);
  const { data } = apiResponse?.response;
  if (data) {
    const { status, token } = data;
    if (status) {
      setAuthToken(token);
      window.location.reload(false);
    }
  }
};
