import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import moment from 'moment';
import { CallApi } from '../util';
import { GET_PROGRESS_REPORT } from './actionType';
import { getProgressReportSuccess, getProgressReportFailed } from './actions';

function* fetchProgressListing({ payload }) {
  const { courseId, enrollFrom, enrollTo } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/report/getProgressReport`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
    params: {
      courseId,
      enrollFrom: enrollFrom ? moment(enrollFrom).format('YYYY-MM-DD') : null,
      enrollTo: enrollTo ? moment(enrollTo).format('YYYY-MM-DD') : null,
    },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getProgressReportSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getProgressReportFailed(message));
  }
}

export function* watchReport() {
  yield takeEvery(GET_PROGRESS_REPORT, fetchProgressListing);
}

export default function* rootSaga() {
  yield all([fork(watchReport)]);
}
