import {
  GET_CHAPTERLISTING,
  GET_CHAPTERLISTING_SUCCESS,
  GET_CHAPTERLISTING_FAILED,
  ADD_CHAPTERLISTING,
  ADD_CHAPTERLISTING_SUCCESS,
  ADD_CHAPTERLISTING_FAILED,
  UPDATE_CHAPTERLISTING,
  UPDATE_CHAPTERLISTING_SUCCESS,
  UPDATE_CHAPTERLISTING_FAILED,
  DELETE_CHAPTERLISTING,
  DELETE_CHAPTERLISTING_SUCCESS,
  DELETE_CHAPTERLISTING_FAILED,
  ADD_CHAPTER_MODAL,
  GET_CHAPTERLISTING_SEQUENCE,
  GET_CHAPTERLISTING_SEQUENCE_SUCCESS,
  GET_CHAPTERLISTING_SEQUENCE_FAILED,
} from './actionType';

export const showChapterModal = () => ({
  type: ADD_CHAPTER_MODAL,
});

export const getChapterListing = (CourseId, isDropDownListing = false) => ({
  type: GET_CHAPTERLISTING,
  payload: { CourseId, isDropDownListing },
});
export const getChapterListingSuccess = (listing) => ({
  type: GET_CHAPTERLISTING_SUCCESS,
  payload: listing,
});
export const getChapterListingFailed = (message) => ({
  type: GET_CHAPTERLISTING_FAILED,
  payload: { message },
});

export const getChapterListingSequence = (CourseId) => ({
  type: GET_CHAPTERLISTING_SEQUENCE,
  payload: { CourseId },
});
export const getChapterListingSequenceSuccess = (sequenceNo) => ({
  type: GET_CHAPTERLISTING_SEQUENCE_SUCCESS,
  payload: sequenceNo,
});
export const getChapterListingSequenceFailed = (message) => ({
  type: GET_CHAPTERLISTING_SEQUENCE_FAILED,
  payload: { message },
});

export const addChapterListing = (Chapters) => ({
  type: ADD_CHAPTERLISTING,
  payload: { Chapters },
});
export const addChapterListingSuccess = () => ({
  type: ADD_CHAPTERLISTING_SUCCESS,
});
export const addChapterListingFailed = (message) => ({
  type: ADD_CHAPTERLISTING_FAILED,
  payload: { message },
});

export const updateChapterListing = (Chapter) => ({
  type: UPDATE_CHAPTERLISTING,
  payload: { Chapter },
});
export const updateChapterListingSuccess = () => ({
  type: UPDATE_CHAPTERLISTING_SUCCESS,
});
export const updateChapterListingFailed = (message) => ({
  type: UPDATE_CHAPTERLISTING_FAILED,
  payload: { message },
});

export const deleteChapterListing = (Chapter) => ({
  type: DELETE_CHAPTERLISTING,
  payload: { Chapter },
});
export const deleteChapterListingSuccess = () => ({
  type: DELETE_CHAPTERLISTING_SUCCESS,
});
export const deleteChapterListingFailed = (message) => ({
  type: DELETE_CHAPTERLISTING_FAILED,
  payload: { message },
});
