import {
  GET_COURSELISTING,
  GET_COURSELISTING_SUCCESS,
  GET_COURSELISTING_FAILED,
  GET_COURSE_TIMELINE,
  GET_COURSE_TIMELINE_SUCCESS,
  GET_COURSE_TIMELINE_FAILED,
} from './actionType';

export const getCourseListing = () => ({
  type: GET_COURSELISTING,
});
export const getCourseListingSuccess = (listing) => ({
  type: GET_COURSELISTING_SUCCESS,
  payload: listing,
});
export const getCourseListingFailed = (message) => ({
  type: GET_COURSELISTING_FAILED,
  payload: { message },
});

export const getCourseTimeline = (courseId) => ({
  type: GET_COURSE_TIMELINE,
  payload: { courseId },
});
export const getCourseTimelineSuccess = (timeline) => ({
  type: GET_COURSE_TIMELINE_SUCCESS,
  payload: timeline,
});
export const getCourseTimelineFailed = (message) => ({
  type: GET_COURSE_TIMELINE_FAILED,
  payload: { message },
});
