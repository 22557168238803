import {
  GET_ENROLLMENT_LISTING,
  GET_ENROLLMENT_LISTING_SUCCESS,
  GET_ENROLLMENT_LISTING_FAILED,
  DELETE_ENROLLMENT,
  DELETE_ENROLLMENT_SUCCESS,
  DELETE_ENROLLMENT_FAILED,
  SHOW_ENROLLMENT_MODAL,
  ADD_ENROLLMENT,
  ADD_ENROLLMENT_SUCCESS,
  ADD_ENROLLMENT_FAILED,
  HIDE_ENROLLMENT_MODAL,
} from './actionType';

export const ShowEnrollmentModal = () => ({
  type: SHOW_ENROLLMENT_MODAL,
});

export const hideEnrollmentModal = () => ({
  type: HIDE_ENROLLMENT_MODAL,
});

export const getEnrollmentListing = (courseId = null) => ({
  type: GET_ENROLLMENT_LISTING,
  payload: { courseId },
});
export const getEnrollmentListingSuccess = (listing) => ({
  type: GET_ENROLLMENT_LISTING_SUCCESS,
  payload: listing,
});
export const getEnrollmentListingFailed = (message) => ({
  type: GET_ENROLLMENT_LISTING_FAILED,
  payload: { message },
});

export const deleteEnrollment = (studentId, courseId) => ({
  type: DELETE_ENROLLMENT,
  payload: { studentId, courseId },
});
export const deleteEnrollmentSuccess = () => ({
  type: DELETE_ENROLLMENT_SUCCESS,
});
export const deleteEnrollmentFailed = (message) => ({
  type: DELETE_ENROLLMENT_FAILED,
  payload: { message },
});

export const addEnrollment = (payload) => ({
  type: ADD_ENROLLMENT,
  payload,
});
export const addEnrollmentSuccess = () => ({
  type: ADD_ENROLLMENT_SUCCESS,
});
export const addEnrollmentFailed = (message) => ({
  type: ADD_ENROLLMENT_FAILED,
  payload: { message },
});
