import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import courses from './courses/reducer';
import scheduler from './scheduler/reducer';
import topics from './topics/reducer';
import users from './users/reducer';
import chapters from './chapters/reducer';
import enrollments from './enrollment/reducer';
import reports from './report/reducer';

import { LOGOUT_USER_SUCCESS } from './actions';

const appReducer = combineReducers({
  menu,
  settings,
  authUser,
  courses,
  scheduler,
  topics,
  users,
  chapters,
  enrollments,
  reports,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === LOGOUT_USER_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
