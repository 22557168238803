import { appRoute } from './defaultValues';

const data = [
  {
    id: 'My Courses',
    icon: 'iconsminds-books',
    label: 'menu.mycourses',
    to: `${appRoute}/mycourses`,
    menuForRoleId: 'Student',
  },
  {
    id: 'Scheduler',
    icon: 'simple-icon-event',
    label: 'menu.scheduler',
    to: `${appRoute}/scheduler`,
    menuForRoleId: 'Student',
  },
  {
    id: 'Planner',
    icon: 'simple-icon-event',
    label: 'menu.scheduler',
    to: `${appRoute}/scheduler/createSchedule`,
  },
  {
    id: 'Report',
    icon: 'iconsminds-statistic',
    label: 'menu.report',
    to: `${appRoute}/report`,
  },
  {
    id: 'Notifications',
    icon: 'iconsminds-bell',
    label: 'menu.notifications',
    to: `${appRoute}/sendNotifications`,
  },
  {
    id: 'Course Listing',
    icon: 'iconsminds-books',
    label: 'menu.courseListing',
    to: `${appRoute}/courses`,
  },
  {
    id: 'Chapter Listing',
    icon: 'iconsminds-books',
    label: 'menu.chapters',
    to: `${appRoute}/chapters`,
  },
  {
    id: 'Topic Listing',
    icon: 'simple-icon-notebook',
    label: 'menu.topicListing',
    to: `${appRoute}/topics`,
  },
  {
    id: 'Topic Types',
    icon: 'simple-icon-notebook',
    label: 'menu.topicTypes',
    to: `${appRoute}/topicTypes`,
  },
  {
    id: 'Users',
    icon: 'simple-icon-people',
    label: 'menu.users',
    to: `${appRoute}/users`,
  },
  {
    id: 'Enrollments',
    icon: 'iconsminds-diploma-2',
    label: 'menu.enrollments',
    to: `${appRoute}/enrollments`,
  },
];
export default data;
