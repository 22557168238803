import {
  GET_USERLISTING,
  GET_USERLISTING_SUCCESS,
  GET_USERLISTING_FAILED,
} from './actionType';

export const getUserListing = () => ({
  type: GET_USERLISTING,
});
export const getUserListingSuccess = (listing) => ({
  type: GET_USERLISTING_SUCCESS,
  payload: listing,
});
export const getUserListingFailed = (message) => ({
  type: GET_USERLISTING_FAILED,
  payload: { message },
});
