/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi } from '../util';
import { GET_USERLISTING } from './actionType';
import { getUserListingSuccess, getUserListingFailed } from './actions';

function* fetchUserListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/users/getUsers`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getUserListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getUserListingFailed(message));
  }
}

export function* watchUsers() {
  yield takeEvery(GET_USERLISTING, fetchUserListing);
}

export default function* rootSaga() {
  yield all([fork(watchUsers)]);
}
