import {
  GET_PROGRESS_REPORT,
  GET_PROGRESS_REPORT_SUCCESS,
  GET_PROGRESS_REPORT_FAILED,
} from './actionType';

const INIT_STATE = {
  progressInfo: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRESS_REPORT:
      return { ...state, loading: true, error: '', progressInfo: null };
    case GET_PROGRESS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        progressInfo: action.payload,
        error: '',
      };
    case GET_PROGRESS_REPORT_FAILED:
      return {
        ...state,
        progressInfo: null,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
