export const GET_CHAPTERLISTING = 'GET_CHAPTERLISTING';
export const GET_CHAPTERLISTING_SUCCESS = 'GET_CHAPTERLISTING_SUCCESS';
export const GET_CHAPTERLISTING_FAILED = 'GET_CHAPTERLISTING_FAILED';

export const GET_CHAPTERLISTING_SEQUENCE = 'GET_CHAPTERLISTING_SEQUENCE';
export const GET_CHAPTERLISTING_SEQUENCE_SUCCESS =
  'GET_CHAPTERLISTING_SEQUENCE_SUCCESS';
export const GET_CHAPTERLISTING_SEQUENCE_FAILED =
  'GET_CHAPTERLISTING_SEQUENCE_FAILED';

export const ADD_CHAPTERLISTING = 'ADD_CHAPTERLISTING';
export const ADD_CHAPTERLISTING_SUCCESS = 'ADD_CHAPTERLISTING_SUCCESS';
export const ADD_CHAPTERLISTING_FAILED = 'ADD_CHAPTERLISTING_FAILED';

export const UPDATE_CHAPTERLISTING = 'UPDATE_CHAPTERLISTING';
export const UPDATE_CHAPTERLISTING_SUCCESS = 'UPDATE_CHAPTERLISTING_SUCCESS';
export const UPDATE_CHAPTERLISTING_FAILED = 'UPDATE_CHAPTERLISTING_FAILED';

export const DELETE_CHAPTERLISTING = 'DELETE_CHAPTERLISTING';
export const DELETE_CHAPTERLISTING_SUCCESS = 'DELETE_CHAPTERLISTING_SUCCESS';
export const DELETE_CHAPTERLISTING_FAILED = 'DELETE_CHAPTERLISTING_FAILED';
export const ADD_CHAPTER_MODAL = 'ADD_CHAPTER_MODAL';
