import {
  GET_ENROLLMENT_LISTING,
  GET_ENROLLMENT_LISTING_SUCCESS,
  GET_ENROLLMENT_LISTING_FAILED,
  DELETE_ENROLLMENT_SUCCESS,
  DELETE_ENROLLMENT_FAILED,
  SHOW_ENROLLMENT_MODAL,
  DELETE_ENROLLMENT,
  HIDE_ENROLLMENT_MODAL,
} from './actionType';

const INIT_STATE = {
  enrollmentListing: null,
  openModal: false,
  isProcessed: true,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ENROLLMENT_MODAL:
      return { ...state, openModal: true };
    case HIDE_ENROLLMENT_MODAL:
      return { ...state, openModal: false };
    case GET_ENROLLMENT_LISTING:
      return { ...state, loading: true, error: '' };
    case GET_ENROLLMENT_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        enrollmentListing: action.payload,
        error: '',
      };
    case GET_ENROLLMENT_LISTING_FAILED:
      return {
        ...state,
        enrollmentListing: null,
        loading: false,
        error: action.payload.message,
      };
    case DELETE_ENROLLMENT:
      return {
        ...state,
        isProcessed: false,
      };
    case DELETE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        courseListing: action.payload,
        openModal: false,
        isProcessed: true,
      };
    case DELETE_ENROLLMENT_FAILED:
      return {
        ...state,
        error: action.payload.message,
        isProcessed: true,
      };
    default:
      return { ...state };
  }
};
