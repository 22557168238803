import {
  GET_SCHEDULE_COURSELISTING,
  GET_SCHEDULE_COURSELISTING_SUCCESS,
  GET_SCHEDULE_COURSELISTING_FAILED,
  GET_SCHEDULE_TIMELINE,
  GET_SCHEDULE_TIMELINE_SUCCESS,
  GET_SCHEDULE_TIMELINE_FAILED,
  REQUEST_SCHEDULE_TIMELINE,
  REQUEST_SCHEDULE_TIMELINE_SUCCESS,
  REQUEST_SCHEDULE_TIMELINE_FAILED,
  SAVE_SCHEDULE_TIMELINE_FAILED,
  SAVE_SCHEDULE_TIMELINE,
  SHOW_SCHEDULE_MODAL,
  SAVE_SCHEDULE_TIMELINE_SUCCESS,
  DELETE_SCHEDULE_TIMELINE,
  DELETE_SCHEDULE_TIMELINE_SUCCESS,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_SUCCESS,
  UPDATE_TASK_STATUS_FAILED,
} from './actionType';

const INIT_STATE = {
  scheduleCourseListing: null,
  schedulerInfo: null,
  schedulerInfoLoading: false,
  openModal: false,
  isProcessed: true,
  loading: false,
  error: '',
};

const updateSchedule = (state, payload) => {
  const { schedulerInfo } = state;
  const { roadMap } = schedulerInfo;
  const { statusId, dayIndex, topicIndex } = payload;

  const filterDay = roadMap && roadMap.length && roadMap[dayIndex];
  const filterTopic = filterDay && filterDay.topics[topicIndex];

  filterTopic.TaskStatusId = statusId;
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHEDULE_COURSELISTING:
      return { ...state, loading: true, error: '' };
    case GET_SCHEDULE_COURSELISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleCourseListing: action.payload,
        error: '',
      };
    case GET_SCHEDULE_COURSELISTING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_SCHEDULE_TIMELINE:
      return { ...state, schedulerInfoLoading: true, error: '' };
    case GET_SCHEDULE_TIMELINE_SUCCESS:
      return {
        ...state,
        schedulerInfoLoading: false,
        schedulerInfo: !action.payload.isEdit && action.payload.SchedulerInfo,
        schedulerInfoEdit:
          action.payload.isEdit && action.payload.SchedulerInfo,
        error: '',
      };
    case GET_SCHEDULE_TIMELINE_FAILED:
      return {
        ...state,
        schedulerInfoLoading: false,
        schedulerInfo: null,
        error: action.payload.message,
      };
    case REQUEST_SCHEDULE_TIMELINE:
      return { ...state, schedulerInfoLoading: true, error: '' };
    case REQUEST_SCHEDULE_TIMELINE_SUCCESS:
      return {
        ...state,
        schedulerInfoLoading: false,
        schedulerInfo: action.payload,
        error: '',
      };
    case REQUEST_SCHEDULE_TIMELINE_FAILED:
      return {
        ...state,
        schedulerInfoLoading: false,
        schedulerInfo: null,
        error: action.payload.message,
      };
    case SAVE_SCHEDULE_TIMELINE:
      return { ...state, schedulerInfoLoading: true, error: '' };
    case SAVE_SCHEDULE_TIMELINE_SUCCESS:
      return {
        ...state,
        schedulerInfoLoading: false,
        isProcessed: true,
        error: '',
      };
    case SAVE_SCHEDULE_TIMELINE_FAILED:
      return {
        ...state,
        schedulerInfoLoading: false,
        error: action.payload.message,
      };
    case SHOW_SCHEDULE_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case DELETE_SCHEDULE_TIMELINE:
      return {
        ...state,
        isProcessed: false,
      };
    case DELETE_SCHEDULE_TIMELINE_SUCCESS:
      return {
        ...state,
        scheduleCourseListing: null,
        isProcessed: true,
        openModal: false,
      };
    case UPDATE_TASK_STATUS:
      return {
        ...state,
      };
    case UPDATE_TASK_STATUS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      updateSchedule(state, action.payload);
      return {
        ...state,
      };
    case UPDATE_TASK_STATUS_FAILED:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
