import {
  GET_SCHEDULE_COURSELISTING,
  GET_SCHEDULE_COURSELISTING_SUCCESS,
  GET_SCHEDULE_COURSELISTING_FAILED,
  GET_SCHEDULE_TIMELINE,
  GET_SCHEDULE_TIMELINE_SUCCESS,
  GET_SCHEDULE_TIMELINE_FAILED,
  REQUEST_SCHEDULE_TIMELINE,
  REQUEST_SCHEDULE_TIMELINE_SUCCESS,
  REQUEST_SCHEDULE_TIMELINE_FAILED,
  SAVE_SCHEDULE_TIMELINE,
  SAVE_SCHEDULE_TIMELINE_SUCCESS,
  SAVE_SCHEDULE_TIMELINE_FAILED,
  DELETE_SCHEDULE_TIMELINE,
  DELETE_SCHEDULE_TIMELINE_SUCCESS,
  DELETE_SCHEDULE_TIMELINE_FAILED,
  SHOW_SCHEDULE_MODAL,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_SUCCESS,
  UPDATE_TASK_STATUS_FAILED,
} from './actionType';

export const showScheduleModal = () => ({
  type: SHOW_SCHEDULE_MODAL,
});

export const getScheduleCourseListing = () => ({
  type: GET_SCHEDULE_COURSELISTING,
});
export const getScheduleCourseListingSuccess = (listing) => ({
  type: GET_SCHEDULE_COURSELISTING_SUCCESS,
  payload: listing,
});
export const getScheduleCourseListingFailed = (message) => ({
  type: GET_SCHEDULE_COURSELISTING_FAILED,
  payload: { message },
});

export const getScheduleTimeline = (ScheduleId, isEdit = false) => ({
  type: GET_SCHEDULE_TIMELINE,
  payload: { ScheduleId, isEdit },
});
export const getScheduleTimelineSuccess = (SchedulerInfo, isEdit) => ({
  type: GET_SCHEDULE_TIMELINE_SUCCESS,
  payload: { SchedulerInfo, isEdit },
});
export const getScheduleTimelineFailed = (message) => ({
  type: GET_SCHEDULE_TIMELINE_FAILED,
  payload: { message },
});

export const requestScheduleTimeline = (params) => ({
  type: REQUEST_SCHEDULE_TIMELINE,
  payload: { params },
});
export const requestScheduleTimelineSuccess = (SchedulerInfo) => ({
  type: REQUEST_SCHEDULE_TIMELINE_SUCCESS,
  payload: SchedulerInfo,
});
export const requestScheduleTimelineFailed = (message) => ({
  type: REQUEST_SCHEDULE_TIMELINE_FAILED,
  payload: { message },
});

export const saveScheduleTimeline = (roadMap, history, isEdit) => ({
  type: SAVE_SCHEDULE_TIMELINE,
  payload: { roadMap, history, isEdit },
});
export const saveScheduleTimelineSuccess = () => ({
  type: SAVE_SCHEDULE_TIMELINE_SUCCESS,
});
export const saveScheduleTimelineFailed = (message) => ({
  type: SAVE_SCHEDULE_TIMELINE_FAILED,
  payload: { message },
});

export const deleteScheduleTimeline = (scheduleId) => ({
  type: DELETE_SCHEDULE_TIMELINE,
  payload: { scheduleId },
});
export const deleteScheduleTimelineSuccess = () => ({
  type: DELETE_SCHEDULE_TIMELINE_SUCCESS,
});
export const deleteScheduleTimelineFailed = (message) => ({
  type: DELETE_SCHEDULE_TIMELINE_FAILED,
  payload: { message },
});

export const updateTaskStatus = (taskId, statusId, dayIndex, topicIndex) => ({
  type: UPDATE_TASK_STATUS,
  payload: { taskId, statusId, dayIndex, topicIndex },
});
export const updateTaskStatusSuccess = (dayIndex, topicIndex, statusId) => ({
  type: UPDATE_TASK_STATUS_SUCCESS,
  payload: { statusId, dayIndex, topicIndex },
});
export const updateTaskStatusFailed = (message) => ({
  type: UPDATE_TASK_STATUS_FAILED,
  payload: { message },
});
