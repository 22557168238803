import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi, showToastMessage } from '../util';
import {
  ADD_ENROLLMENT,
  DELETE_ENROLLMENT,
  GET_ENROLLMENT_LISTING,
} from './actionType';
import {
  getEnrollmentListingSuccess,
  getEnrollmentListingFailed,
  deleteEnrollmentSuccess,
  deleteEnrollmentFailed,
  addEnrollmentSuccess,
  addEnrollmentFailed,
  hideEnrollmentModal,
} from './actions';

function* fetchEnrollmentListing({ payload }) {
  const { courseId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/enrollment/getEnrollment${
      courseId ? `?courseId=${courseId}` : ''
    }`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getEnrollmentListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getEnrollmentListingFailed(message));
  }
}

function* cancelEnrollment({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/enrollment/deleteEnrollment`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    params: payload,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(deleteEnrollmentSuccess(data));
    showToastMessage(status, 'Enrollment cancelled successfully');
  } else {
    const { message } = response.data;
    yield put(deleteEnrollmentFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

function* addEnrollment({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/enrollment/postEnrollment`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
    data: payload,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(addEnrollmentSuccess(data));
    yield put(hideEnrollmentModal());
    showToastMessage(status, 'Student enrolled successfully');
  } else {
    const { message } = response.data;
    yield put(addEnrollmentFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

export function* watchEnrollment() {
  yield takeEvery(GET_ENROLLMENT_LISTING, fetchEnrollmentListing);
  yield takeEvery(DELETE_ENROLLMENT, cancelEnrollment);
  yield takeEvery(ADD_ENROLLMENT, addEnrollment);
}

export default function* rootSaga() {
  yield all([fork(watchEnrollment)]);
}
