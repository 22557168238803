import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import courseSagas from './courses/saga';
import schedulerSagas from './scheduler/saga';
import topicSaga from './topics/saga';
import userSaga from './users/saga';
import enrollmentSagas from './enrollment/saga';
import chapterSagas from './chapters/saga';
import ReportSagas from './report/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    courseSagas(),
    schedulerSagas(),
    topicSaga(),
    userSaga(),
    enrollmentSagas(),
    chapterSagas(),
    ReportSagas(),
  ]);
}
