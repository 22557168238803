import {
  GET_TOPICLISTING,
  GET_TOPICLISTING_SUCCESS,
  GET_TOPICLISTING_FAILED,
  ADD_TOPICLISTING,
  ADD_TOPICLISTING_SUCCESS,
  ADD_TOPICLISTING_FAILED,
  UPDATE_TOPICLISTING,
  UPDATE_TOPICLISTING_SUCCESS,
  UPDATE_TOPICLISTING_FAILED,
  DELETE_TOPICLISTING,
  DELETE_TOPICLISTING_SUCCESS,
  DELETE_TOPICLISTING_FAILED,
  GET_TOPIC_TYPELISTING,
  GET_TOPIC_TYPELISTING_FAILED,
  GET_TOPIC_TYPELISTING_SUCCESS,
  ADD_TOPIC_TYPELISTING,
  ADD_TOPIC_TYPELISTING_FAILED,
  ADD_TOPIC_TYPELISTING_SUCCESS,
  DELETE_TOPIC_TYPELISTING,
  DELETE_TOPIC_TYPELISTING_FAILED,
  DELETE_TOPIC_TYPELISTING_SUCCESS,
  UPDATE_TOPIC_TYPELISTING,
  UPDATE_TOPIC_TYPELISTING_FAILED,
  UPDATE_TOPIC_TYPELISTING_SUCCESS,
  ADD_TOPIC_MODAL,
  UPDATE_TOPIC_SORTING,
  UPDATE_TOPIC_SORTING_SUCCESS,
  UPDATE_TOPIC_SORTING_FAILED,
  GET_TOPICLISTING_SEQUENCE,
  GET_TOPICLISTING_SEQUENCE_SUCCESS,
  GET_TOPICLISTING_SEQUENCE_FAILED,
} from './actionType';

export const showTopicModal = () => ({
  type: ADD_TOPIC_MODAL,
});

export const getTopicListing = (CourseId) => ({
  type: GET_TOPICLISTING,
  payload: { CourseId },
});
export const getTopicListingSuccess = (listing) => ({
  type: GET_TOPICLISTING_SUCCESS,
  payload: listing,
});
export const getTopicListingFailed = (message) => ({
  type: GET_TOPICLISTING_FAILED,
  payload: { message },
});

export const getTopicListingSequence = (CourseId) => ({
  type: GET_TOPICLISTING_SEQUENCE,
  payload: { CourseId },
});
export const getTopicListingSequenceSuccess = (sequenceNo) => ({
  type: GET_TOPICLISTING_SEQUENCE_SUCCESS,
  payload: sequenceNo,
});
export const getTopicListingSequenceFailed = (message) => ({
  type: GET_TOPICLISTING_SEQUENCE_FAILED,
  payload: { message },
});

export const addTopicListing = (Topics) => ({
  type: ADD_TOPICLISTING,
  payload: { Topics },
});
export const addTopicListingSuccess = () => ({
  type: ADD_TOPICLISTING_SUCCESS,
});
export const addTopicListingFailed = (message) => ({
  type: ADD_TOPICLISTING_FAILED,
  payload: { message },
});

export const updateTopicListing = (Topic) => ({
  type: UPDATE_TOPICLISTING,
  payload: { Topic },
});
export const updateTopicListingSuccess = () => ({
  type: UPDATE_TOPICLISTING_SUCCESS,
});
export const updateTopicListingFailed = (message) => ({
  type: UPDATE_TOPICLISTING_FAILED,
  payload: { message },
});

export const deleteTopicListing = (Topic) => ({
  type: DELETE_TOPICLISTING,
  payload: { Topic },
});
export const deleteTopicListingSuccess = () => ({
  type: DELETE_TOPICLISTING_SUCCESS,
});
export const deleteTopicListingFailed = (message) => ({
  type: DELETE_TOPICLISTING_FAILED,
  payload: { message },
});

export const getTopicTypeListing = () => ({
  type: GET_TOPIC_TYPELISTING,
});
export const getTopicTypeListingSuccess = (listing) => ({
  type: GET_TOPIC_TYPELISTING_SUCCESS,
  payload: listing,
});
export const getTopicTypeListingFailed = (message) => ({
  type: GET_TOPIC_TYPELISTING_FAILED,
  payload: { message },
});

export const addTopicTypeListing = (Types) => ({
  type: ADD_TOPIC_TYPELISTING,
  payload: { Types },
});
export const addTopicTypeListingSuccess = () => ({
  type: ADD_TOPIC_TYPELISTING_SUCCESS,
});
export const addTopicTypeListingFailed = (message) => ({
  type: ADD_TOPIC_TYPELISTING_FAILED,
  payload: { message },
});

export const updateTopicTypeListing = (Type) => ({
  type: UPDATE_TOPIC_TYPELISTING,
  payload: { Type },
});
export const updateTopicTypeListingSuccess = () => ({
  type: UPDATE_TOPIC_TYPELISTING_SUCCESS,
});
export const updateTopicTypeListingFailed = (message) => ({
  type: UPDATE_TOPIC_TYPELISTING_FAILED,
  payload: { message },
});

export const deleteTopicTypeListing = (Type) => ({
  type: DELETE_TOPIC_TYPELISTING,
  payload: { Type },
});
export const deleteTopicTypeListingSuccess = () => ({
  type: DELETE_TOPIC_TYPELISTING_SUCCESS,
});
export const deleteTopicTypeListingFailed = (message) => ({
  type: DELETE_TOPIC_TYPELISTING_FAILED,
  payload: { message },
});

export const updateTopicSorting = (Listing) => ({
  type: UPDATE_TOPIC_SORTING,
  payload: { Listing },
});
export const updateTopicSortingSuccess = () => ({
  type: UPDATE_TOPIC_SORTING_SUCCESS,
});
export const updateTopicSortingFailed = (message) => ({
  type: UPDATE_TOPIC_SORTING_FAILED,
  payload: { message },
});
