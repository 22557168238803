export const GET_SCHEDULE_COURSELISTING = 'GET_SCHEDULE_COURSELISTING';
export const GET_SCHEDULE_COURSELISTING_SUCCESS =
  'GET_SCHEDULE_COURSELISTING_SUCCESS';
export const GET_SCHEDULE_COURSELISTING_FAILED =
  'GET_SCHEDULE_COURSELISTING_FAILED';

export const GET_SCHEDULE_TIMELINE = 'GET_SCHEDULE_TIMELINE';
export const GET_SCHEDULE_TIMELINE_SUCCESS = 'GET_SCHEDULE_TIMELINE_SUCCESS';
export const GET_SCHEDULE_TIMELINE_FAILED = 'GET_SCHEDULE_TIMELINE_FAILED';

export const REQUEST_SCHEDULE_TIMELINE = 'REQUEST_SCHEDULE_TIMELINE';
export const REQUEST_SCHEDULE_TIMELINE_SUCCESS =
  'REQUEST_SCHEDULE_TIMELINE_SUCCESS';
export const REQUEST_SCHEDULE_TIMELINE_FAILED =
  'REQUEST_SCHEDULE_TIMELINE_FAILED';

export const SAVE_SCHEDULE_TIMELINE = 'SAVE_SCHEDULE_TIMELINE';
export const SAVE_SCHEDULE_TIMELINE_SUCCESS = 'SAVE_SCHEDULE_TIMELINE_SUCCESS';
export const SAVE_SCHEDULE_TIMELINE_FAILED = 'SAVE_SCHEDULE_TIMELINE_FAILED';

export const DELETE_SCHEDULE_TIMELINE = 'DELETE_SCHEDULE_TIMELINE';
export const DELETE_SCHEDULE_TIMELINE_SUCCESS =
  'DELETE_SCHEDULE_TIMELINE_SUCCESS';
export const DELETE_SCHEDULE_TIMELINE_FAILED =
  'DELETE_SCHEDULE_TIMELINE_FAILED';

export const SHOW_SCHEDULE_MODAL = 'SHOW_SCHEDULE_MODAL';

export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const UPDATE_TASK_STATUS_SUCCESS = 'UPDATE_TASK_STATUS_SUCCESS';
export const UPDATE_TASK_STATUS_FAILED = 'UPDATE_TASK_STATUS_FAILED';
