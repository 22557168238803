/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi, showToastMessage } from '../util';
import {
  ADD_TOPICLISTING,
  ADD_TOPIC_TYPELISTING,
  DELETE_TOPICLISTING,
  DELETE_TOPIC_TYPELISTING,
  GET_TOPICLISTING,
  GET_TOPICLISTING_SEQUENCE,
  GET_TOPIC_TYPELISTING,
  UPDATE_TOPICLISTING,
  UPDATE_TOPIC_SORTING,
  UPDATE_TOPIC_TYPELISTING,
} from './actionType';
import {
  getTopicListingSuccess,
  getTopicListingFailed,
  getTopicTypeListingSuccess,
  getTopicTypeListingFailed,
  addTopicListingSuccess,
  addTopicListingFailed,
  addTopicTypeListingSuccess,
  addTopicTypeListingFailed,
  updateTopicTypeListingSuccess,
  updateTopicTypeListingFailed,
  deleteTopicListingSuccess,
  deleteTopicListingFailed,
  deleteTopicTypeListingSuccess,
  deleteTopicTypeListingFailed,
  updateTopicSortingSuccess,
  updateTopicSortingFailed,
  getTopicListingSequenceSuccess,
  getTopicListingSequenceFailed,
} from './actions';

function* fetchTopicListing({ payload }) {
  const { CourseId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicListing/getTopicListing?courseId=${CourseId}&isGroup=${false}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getTopicListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getTopicListingFailed(message));
  }
}

function* fetchTopicListingSequence({ payload }) {
  const { CourseId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicListing/getTopicListing?courseId=${CourseId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    const tempdata = []
      .concat(data)
      .sort((a, b) => (a?.sortId < b.sortId ? 1 : -1));

    let lastSequenceNo = 0;

    if (tempdata.length) {
      lastSequenceNo = tempdata[0]?.sortId != null ? tempdata[0]?.sortId : 0;
    }

    yield put(getTopicListingSequenceSuccess(lastSequenceNo));
  } else {
    const { message } = response.data;
    yield put(getTopicListingSequenceFailed(message));
  }
}

function* addTopicListing({ payload }) {
  const { Topics } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicListing/addTopicListing`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
    data: Topics,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(addTopicListingSuccess());
    showToastMessage(status, 'Add Topic Successfully');
  } else {
    const { message } = response.data;
    yield put(addTopicListingFailed(message));
    showToastMessage(status, message);
  }
}

function* updateTopicListing({ payload }) {
  const { Topic } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicListing/updateTopicListing`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: Topic,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(addTopicListingSuccess());
    showToastMessage(status, 'Update topic successfully');
  } else {
    const { message } = response.data;
    yield put(addTopicListingFailed(message));
    showToastMessage(status, message);
  }
}

function* deleteTopicListing({ payload }) {
  const { Topic } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicListing/deleteTopicListing`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'DELETE',
    data: Topic,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(deleteTopicListingSuccess());
    showToastMessage(status, 'Delete topic successfully');
  } else {
    const { message } = response.data;
    yield put(deleteTopicListingFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

function* fetchTopicTypeListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/topicType/getTopicType`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    const dataList = data.map((item) => ({
      label: item.Name,
      value: item.Description,
      key: item.Id,
      other: item,
    }));
    yield put(getTopicTypeListingSuccess(dataList));
  } else {
    const { message } = response.data;
    yield put(getTopicTypeListingFailed(message));
  }
}

function* addTypeListing({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/topicType/addTopicType`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
    data: payload.Types,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;
  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(addTopicTypeListingSuccess(data));
    showToastMessage(status, 'Add topic type successfully');
  } else {
    const { message } = response.data;
    yield put(addTopicTypeListingFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

function* updateTopicTypeListing({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/topicType/updateTopicType`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: payload.Type,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(updateTopicTypeListingSuccess());
    showToastMessage(status, 'Update topic type successfully');
  } else {
    const { message } = response.data;
    yield put(updateTopicTypeListingFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

function* deleteTopicTypeListing({ payload }) {
  const { CourseId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicType/deleteTopicType`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'DELETE',
    data: payload.Type,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(deleteTopicTypeListingSuccess());
    showToastMessage(status, 'Delete topic type successfully');
  } else {
    const { message } = response.data;
    yield put(deleteTopicTypeListingFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

function* updateTopicSorting({ payload }) {
  const { Listing } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/topicListing/updateTopicListingWithSorting`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: Listing,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(updateTopicSortingSuccess());
    showToastMessage(status, 'Update Listing Successfully');
  } else {
    const { message } = response.data;
    yield put(updateTopicSortingFailed(message));
    showToastMessage(status, 'Update Listing Failed');
  }
}

export function* watchTopics() {
  yield takeEvery(GET_TOPICLISTING, fetchTopicListing);
  yield takeEvery(ADD_TOPICLISTING, addTopicListing);
  yield takeEvery(DELETE_TOPICLISTING, deleteTopicListing);
  yield takeEvery(GET_TOPIC_TYPELISTING, fetchTopicTypeListing);
  yield takeEvery(ADD_TOPIC_TYPELISTING, addTypeListing);
  yield takeEvery(UPDATE_TOPICLISTING, updateTopicListing);
  yield takeEvery(UPDATE_TOPIC_TYPELISTING, updateTopicTypeListing);
  yield takeEvery(DELETE_TOPIC_TYPELISTING, deleteTopicTypeListing);
  yield takeEvery(UPDATE_TOPIC_SORTING, updateTopicSorting);
  yield takeEvery(GET_TOPICLISTING_SEQUENCE, fetchTopicListingSequence);
}

export default function* rootSaga() {
  yield all([fork(watchTopics)]);
}
