/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { adminRoot, baseURL } from 'constants/defaultValues';
import menuItems from 'constants/menu';
import {
  getAuthToken,
  setCurrentUser,
  setCurrentUserMenu,
} from 'helpers/Utils';
import { REGISTER_USER } from 'redux/actions';
import { CallApi, showToastMessage } from '../util';
import {
  AUTHENTICATE_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SEND_NOTIFICATION,
} from './actionType';
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  SendNotificationSuccess,
  SendNotificationError,
} from './actions';

function* userLogin({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/auth/login`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data: {
      email,
      password,
    },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data, token } = response.data;
    setCurrentUser({ ...data, role: 1 }, token);

    if (data.IsTrial <= 1) {
      setCurrentUserMenu(
        menuItems.filter((x) => x.menuForRoleId === 'Student')
      );
    } else {
      setCurrentUserMenu(
        menuItems.filter((x) => x.menuForRoleId !== 'Student')
      );
    }

    yield put(loginUserSuccess({ ...data, role: 1 }));
    history.push(adminRoot);
  } else {
    const { message } = response.data;
    yield put(loginUserError(message));
  }
}

function* userAuthenticate({ payload }) {
  const { email } = payload.user;
  const { history } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/auth/authenticateUser`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data: {
      email,
    },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data, token } = response.data;

    localStorage.setItem('studentLogin', true);

    setCurrentUser({ ...data, role: 1 }, token);

    if (!data.IsTrial) {
      setCurrentUserMenu(
        menuItems.filter((x) => x.menuForRoleId === 'Student')
      );
    } else {
      setCurrentUserMenu(
        menuItems.filter((x) => x.menuForRoleId !== 'Student')
      );
    }

    yield put(loginUserSuccess({ ...data, role: 1 }));
    history.push(adminRoot);
  } else {
    const { message } = response.data;
    yield put(loginUserError(message));
  }
}

function* registerUser({ payload }) {
  const { history } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/auth/signup`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data: payload.user,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(registerUserSuccess());
    history.push(adminRoot);
  } else {
    const { message } = response.data;
    yield put(registerUserError(message));
  }
}

function* sendNotifications({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/notification/NotifyUsers`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
    data: payload,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    yield put(SendNotificationSuccess());
    showToastMessage(status, 'Notification sent successfully');
  } else {
    const { message } = response.data;
    yield put(SendNotificationError(message));
    showToastMessage(status, message);
  }
}

function logout({ payload }) {
  // eslint-disable-next-line no-unused-vars
  const { history } = payload;

  const isStd = localStorage.getItem('studentLogin');
  if (isStd) {
    history.push('/user/authenticate');
  }
  setCurrentUser();
  setCurrentUserMenu();
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, userLogin);
  yield takeEvery(LOGOUT_USER, logout);
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(AUTHENTICATE_USER, userAuthenticate);
  yield takeEvery(SEND_NOTIFICATION, sendNotifications);
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser)]);
}
