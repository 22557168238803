import {
  GET_PROGRESS_REPORT,
  GET_PROGRESS_REPORT_SUCCESS,
  GET_PROGRESS_REPORT_FAILED,
} from './actionType';

export const getProgressReport = (
  courseId = null,
  enrollFrom = null,
  enrollTo = null
) => ({
  type: GET_PROGRESS_REPORT,
  payload: { courseId, enrollFrom, enrollTo },
});
export const getProgressReportSuccess = (listing) => ({
  type: GET_PROGRESS_REPORT_SUCCESS,
  payload: listing,
});
export const getProgressReportFailed = (message) => ({
  type: GET_PROGRESS_REPORT_FAILED,
  payload: { message },
});
